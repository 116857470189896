 // import "../styles/globals.scss";
 // import "../styles/product_page.scss";
 // import "../styles/product_comparison.scss";
 // import "../styles/image_gallery.scss";
 // import "../styles/product_search.scss";
 // import "../styles/agent_locator.scss";
 // import "../styles/simple_modal.scss";
 // import "../styles/paginator.scss";
 // import "../styles/tooltip.scss";
 // import "../styles/downloads.scss";
 // import "../styles/collections.scss";
 // import "../styles/saved_items.scss"; 
 // import "../styles/tables.scss"; 
 // import "../styles/resources.scss"; 

import {useEffect} from 'react'
import ThemeWrapper from '@/components/theme_wrapper.js';
import {ProductCacheWrapper} from '@/lib/product_cache_context.js'; 
import HeaderHtml from '@/components/header_html.js'  


const getPathName = (ctx) => {
  let path = ctx.pathname.replace('/manufacturers/[token]', '');
   Array.from(path.matchAll(/\[(?<key>\w*)\]/g))
                      .filter((d, i) => d.groups && d.groups.key)
                      .forEach((d, i) =>  {
                            path = path.replace(`[${d.groups.key}]`, ctx.query[d.groups.key])
                        })
  return '/lex-cms' + path//.replace(/\?.*/,'');
}



function MyApp({ Component, pageProps, router }) {
  if (typeof window === 'undefined') {
   let currentPath;
    try {
     currentPath = getPathName(router);
    }
    catch (e) {

    }
    if (pageProps.page_layout) {
      pageProps.page_layout.currentPath = currentPath
    }
  }

  return (
  <ProductCacheWrapper items={pageProps.products} 
                       collection={pageProps.collection }
                       attributeFilters={pageProps.attribute_filters }> 
   <ThemeWrapper page_layout={pageProps.page_layout }> 
    <Component {...pageProps} />
    </ThemeWrapper >
  </ProductCacheWrapper>
  );
}


export default MyApp;
