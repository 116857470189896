import {memo, useEffect} from 'react'; 
function FooterHtml({page_layout }) {
 
  return (
    <>
          {page_layout?.footer_html &&
            <div dangerouslySetInnerHTML={{__html:  page_layout.footer_html}}></div>
          }
    </>

  )
}


export default memo(FooterHtml);

