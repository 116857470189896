import {createContext, useEffect, useState} from 'react';
import {useRouter } from 'next/router'; 
import Qs from 'qs'; 
import { productRatingOptions, sortOptions } from "@/lib/static_data.js";

const ProductCacheContext = createContext({})


const pathNameMap = {
  '/lex-cms/products/' :  (q, slug) => {
       return {pathname: '/products/[index]', query: {index: slug, ...q}}
     } , 
  '/lex-cms/product/' :  (q, slug) => {
       return {pathname: '/product/[slug]', query: {slug: slug} }},

  '/lex-cms/collections/' : (q, slug) =>  ({pathname: '/collections/[slug]', query: {slug: slug,...q}} )
}

export function ProductCacheWrapper(props) {
  const [items, setItems] = useState(props.items || [])
  const [scroll, setScroll] = useState(0); 
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0); 
  const [collectionFilters, setCollectionFilters] = useState([]) ;
  const [attributeFilters, setAttributeFilters] = useState(props.attribute_filters || []);
  const [typeOptions, setTypeOptions] = useState([]); 
  const [collection, setCollection] = useState(props.collection || ''); 

  const [ratingAggregation, setRatingAggregation] = useState( {} );
  const router = useRouter()
  const listenForBack = async (e) => {

    if (items?.length > 0 && scroll  ) {
      e.preventDefault();
      let q =  Qs.parse(window.location.search.replace('?', ''),  {arrayFormat: 'brackets', 
        encode: true} );
       q.clientNavigation = 1; 
       const slug = window.location.pathname.split('/').slice(-1); 
       const path = window.location.pathname.replace(/[^\/]+$/, '')
       const pathName = pathNameMap[path]


     if (path != '/lex-cms/product/') {

      let result = await router.push(pathName(q, slug), window.location.href, {scroll: false})
       window.scrollTo({top: scroll, behavior: 'instant'})
      }

      else {
        let result = await router.push(pathName(q, slug), window.location.href, {scroll: true})
       window.scrollTo({top: 0, behavior: 'instant'})


      }
    }
    else {
      window.location = window.location.href
    }

  }

  const selectedValues = (d, search, selected) => {
    d?.product_attribute_values?.forEach((attr, j) => {

      if (
        search["q[has_attribute_values][]"] &&
        search["q[has_attribute_values][]"].includes(attr.id.toString())
      ) {
        selected.push(attr);
      }
    });
  };
  const selectedAttributes = (search) => {
    let selected = [];
    attributeFilters.forEach((d, i) => {
      d.children?.length > 0
        ? d.children.forEach((_d, i) => selectedValues(_d, search, selected))
        : selectedValues(d, search, selected);
    });
    return selected;
  };

  const showRatingFilter = () => {
    return Object.values(ratingAggregation).some((val) => val > 0);
  };

  const _productRatingOptions = () => {
    return productRatingOptions.filter((d, i) => {
      let key = d.label.toLowerCase() + "_rating";
      return ratingAggregation[key] > 0;
    });
  };

  const attributesApplied = items && items.some((product) => product.attributes?.length > 0)

  useEffect(() => {
    window.addEventListener('popstate', listenForBack)
    return () => {window.removeEventListener('popstate', listenForBack) }
  }, [scroll])
  
  useEffect(() => {
    router && router.beforePopState( ({ as} ) => {
      return false;
    })
    return () => {  router &&   router.beforePopState( ({ as} ) => {
      return true;
    })}
   }, [scroll])

  const updateScroll = (e ) => {
    if (/\/lex-cms\/product\//.test(e)) {
     setScroll(window.pageYOffset)
    }
    else {
      setScroll(null);
    }
  }

  const appendProducts = (prods) => {
   setItems((prev) => [...prev, ...prods] )

  }


  useEffect(() => {
    router  && router.events.on('routeChangeStart', updateScroll)
    return () =>router && router.events.off('routeChangeStart', updateScroll)
  }, [])


  return (
    <ProductCacheContext.Provider value={{items: items, 
                                          setItems: setItems, 
                                          page: page, 
                                          setPage: setPage,
                                          appendProducts: appendProducts, 
                                          attributeFilters:  attributeFilters, 
                                          setTypeOptions: setTypeOptions, 
                                          typeOptions: typeOptions,
                                          setAttributeFilters: setAttributeFilters,
                                          collectionFilters: collectionFilters, 
                                          setCollectionFilters: setCollectionFilters,
                                          setRatingAggregation: setRatingAggregation, 
                                          ratingAggregation: ratingAggregation,
                                          attributesApplied: attributesApplied, 
                                          _productRatingOptions: _productRatingOptions, 
                                          selectedAttributes: selectedAttributes, 
                                          showRatingFilter: showRatingFilter, 
                                          selectedValues: selectedValues,
                                          totalCount: totalCount, 
                                          collection: collection, 
                                          setCollection: setCollection, 
                                          setTotalCount: setTotalCount}} > 
      {props.children}
    </ProductCacheContext.Provider>
  )
}
export { ProductCacheContext };

