import {useEffect } from 'react';
import ConditionalWrapper from '@/components/conditional_wrapper.js'
import HeaderHtml from '@/components/header_html.js'
import FooterHtml from '@/components/footer_html.js'
import JavascriptLoader from '@/components/javascript_loader.js'
export default function ThemeWrapper({page_layout, children}) {
  
  return (
    <>
        <ConditionalWrapper
          condition={page_layout?.wrapper_classes || page_layout?.wrapper_id}
          wrapper={children => <div id={page_layout?.wrapper_id} 
          suppressHydrationWarning={true}
          className={page_layout?.wrapper_classes}>{children}</div>}
        >
         {page_layout?.stylesheets?.length > 0 && 
           page_layout.stylesheets.filter((d, i) => typeof d.body !== 'undefined' ).map((d, i) => { 
             return (<style id={d.id} key={d.id ||  i}
               dangerouslySetInnerHTML={{ __html: d.body }} />)
            })
         }
         <JavascriptLoader scripts={page_layout?.scripts }/>
         <HeaderHtml page_layout={page_layout} /> 
          {children}
         <FooterHtml page_layout={page_layout}   />
        </ConditionalWrapper>
    </>
  ); 
}

