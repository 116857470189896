import Script from 'next/script'; 
import {useEffect, useState } from 'react';
import {useRouter} from 'next/router'; 

export default function JavascriptLoader({scripts}) {
  
  return (
    <>
      {scripts?.length > 0 && 
              scripts.map((script, i) => {
                return script.src ? (
                  <Script key={i} src={script.src} id={script.id } strategy={script.load_strategy || 'beforeInteractive'}  />) : 
                  (<Script key={i} type={script.type} strategy={script.load_strategy || 'afterInteractive'}  
                   id={(script.id || i )}>{`${script.body };`  } </Script>  )
           })
          }
    </>
  )
}
