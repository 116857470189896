
import {getActiveNavItem} from  '@/lib/utils.js';
import {memo, useEffect, useState} from 'react'; 
function HeaderHtml({page_layout }) {
  let headerHTML; 
  headerHTML = getActiveNavItem(page_layout);
  headerHTML = headerHTML ? headerHTML : page_layout?.header_html;

  return (
    <>
      {headerHTML  &&
      <div suppressHydrationWarning={true} dangerouslySetInnerHTML={{__html:  headerHTML}}></div>
      }
    </>

  )
}


export default memo(HeaderHtml);

